import React from 'react'
import { CellInsertParams, CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  CandidateInterviewStageTableRowType,
  CandidateSidebarTypes,
  InterviewFeedbackStatus,
  InterviewStageWithoutRoundInterface,
  InterviewType,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { Box, chain, Flex, Text, Token } from '@revolut/ui-kit'
import { format } from 'date-fns'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { InterviewResultIcon } from '@src/components/ColumnInserts/InterviewResult/InterviewResultIcon'
import { ratingToColor } from '@src/utils/performance'
import PerformanceRatingLabelTag from '@src/components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { StageStatus } from '@src/pages/Forms/Candidate/StagesTable/StageStatus'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { AutomatedStageIndicator } from '@src/pages/Forms/Candidate/StagesTable/AutomatedStageIndicator'

const getDateTimeWithoutCurrentYear = (date: Date) =>
  format(
    date,
    date.getFullYear() === new Date().getFullYear()
      ? 'd MMM, HH:mm'
      : 'd MMM yyyy, HH:mm',
  )

export const createInterviewStageNameColumn = (
  currentStageId: number,
): ColumnInterface<CandidateInterviewStageTableRowType> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
  insert: ({ data }) => {
    if ('children' in data) {
      const scheduledDateTime = data.interview_feedbacks.filter(
        item => item.status !== InterviewFeedbackStatus.rejected,
      )[0]?.scheduled_date_time

      return (
        <Flex gap="s-8">
          <Box>
            {chain(
              <Text fontWeight={500}>{data.title}</Text>,
              currentStageId === data.id && <Text fontWeight={500}>Current</Text>,
              scheduledDateTime && (
                <Text color={Token.color.greyTone50}>
                  {getDateTimeWithoutCurrentYear(new Date(scheduledDateTime))}
                </Text>
              ),
            )}
          </Box>
          <AutomatedStageIndicator stage={data} />
        </Flex>
      )
    }

    if (!data.interviewer) {
      return '-'
    }

    let date

    if (
      (data.status === InterviewFeedbackStatus.completed ||
        data.status === InterviewFeedbackStatus.rejected) &&
      data.updated_date_time
    ) {
      date = new Date(data.updated_date_time)
    }

    return (
      <Flex alignItems="center">
        {chain(
          <UserWithAvatar {...data.interviewer} />,
          date && (
            <Text color={Token.color.greyTone50}>
              {getDateTimeWithoutCurrentYear(date)}
            </Text>
          ),
        )}
      </Flex>
    )
  },
})

export const createInterviewStageActionColumn = ({
  stages,
  roundId,
  currentStageId,
  candidateId,
  canAddFeedback,
  canCancel,
  canViewEditOffer,
  onRefresh,
  onTriggerAction,
}: {
  stages: InterviewStageWithoutRoundInterface[]
  roundId: number
  currentStageId: number
  candidateId: number
  canAddFeedback: boolean
  canCancel: boolean
  canViewEditOffer: boolean
  onRefresh?: () => void
  onTriggerAction?: (
    data: CandidateInterviewStageTableRowType,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
    actionType?: CandidateSidebarTypes,
  ) => void
}): ColumnInterface<CandidateInterviewStageTableRowType> => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: '',
  insert: ({ data }: CellInsertParams<CandidateInterviewStageTableRowType>) =>
    'children' in data ? (
      <Flex onClick={e => e.stopPropagation()}>
        <StageActions
          stages={stages}
          stage={data}
          roundId={roundId}
          canCancelInterview={canCancel}
          canAddFeedback={canAddFeedback}
          canRejectFeedback={canCancel}
          onRefresh={onRefresh}
          currentStageId={currentStageId}
          onTriggerStageAction={(d, mode, actionType) => {
            onTriggerAction?.(
              { ...d, children: [], recommendations: [] },
              mode,
              undefined,
              actionType,
            )
          }}
          menuType="dropdown"
          canViewEditOffer={canViewEditOffer}
          candidateId={candidateId}
        />
      </Flex>
    ) : (
      ''
    ),
})

export const createInterviewStageRecommendationColumn = (
  seniority: SeniorityInterface | null,
): ColumnInterface<CandidateInterviewStageTableRowType> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Result',
  insert: ({ data }) => {
    if ('children' in data) {
      return ''
    }

    if (!data.status) {
      return <Text color="grey-20">-</Text>
    }
    return (
      <InterviewResultIcon
        result={data.result}
        status={data.status}
        showTooltip
        asTag
        higherSeniority={
          seniority && data.evaluated_seniority
            ? seniority.level < data.evaluated_seniority.level
            : undefined
        }
        lowerSeniority={
          seniority && data.evaluated_seniority
            ? seniority.level > data.evaluated_seniority.level
            : undefined
        }
      />
    )
  },
})

export const createInterviewStageResultColumn = (
  stages: InterviewStageWithoutRoundInterface[],
  currentStageId: number,
): ColumnInterface<CandidateInterviewStageTableRowType> => ({
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  background: data => ('children' in data ? '' : ratingToColor(data.rating) || ''),
  insert: ({ data }) => {
    if ('children' in data) {
      let isNext = true

      for (let i = 0, len = stages.length; i < len; ++i) {
        if (stages[i].id === currentStageId) {
          if (currentStageId === data.id) {
            isNext = false
          }
          break
        }

        if (stages[i].id === data.id) {
          isNext = false
          break
        }
      }

      return <StageStatus stage={data} isNext={isNext} />
    }
    return data.rating ? (
      <PerformanceRatingLabelTag
        fontSize="caption"
        fontWeight={400}
        rating={data.rating}
      />
    ) : (
      '-'
    )
  },
})
