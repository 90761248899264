import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CandidateInterface,
  InterviewPendingSchedulingStatuses,
  InterviewPendingSchedulingTypes,
  InterviewRoundInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewType,
  ScheduleSidebarModeType,
  InterviewStageWithoutRoundInterface,
  CandidateSidebarTypes,
  InterviewFeedbackWithoutStageInterface,
} from '@src/interfaces/interviewTool'
import UpcomingInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingInterviewNotifications'
import { Box, Widget } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import InterviewStartCard from '@src/pages/Forms/Candidate/InterviewProgress/InterviewStartCard'
import { InterviewFeedbackSidebar } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedbackSidebar'
import ArchiveDetails from '@src/pages/Forms/Candidate/InterviewProgress/components/ArchiveDetails'
import CandidateShortSummarySidebar from '@src/features/InterviewTool/CandidateShortSummarySidebar'
import { ROUTES } from '@src/constants/routes'
import UpcomingOnlineTestNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOnlineTestNotifications'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import AnonymizeNotice from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/AnonymizeNotice'
import UpcomingOfferNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOfferNotifications'
import ChangeRoundWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ChangeRoundWidget'
import ConfidentialCandidateWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ConfidentialCandidateWidget'
import PrepCallInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/PrepCallInterviewNotifications'
import { useGetOfferSettings } from '@src/api/settings'
import Comments from '@src/pages/Forms/Candidate/Comments/Comments'
import CVScreeningNotification from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/CVScreeningNotification'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { SnoozeBanner } from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { StagesTable } from '@src/pages/Forms/Candidate/StagesTable/StagesTable'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import CandidateInformation from '@src/pages/Forms/Candidate/CandidateInformation'
import { SendOnlineTestSidebar } from '@src/pages/Forms/SendOnlineTest/SendOnlineTestSidebar'
import { HiringStageSwitcher } from '@src/pages/Forms/Candidate/StagesTable/HiringStageSwitcher'
import MultipleDetailsSkeleton from '@components/Skeletons/MultipleDetailsSkeleton'
import { useRefreshInterviewStages } from '@src/pages/Forms/Candidate/utils'
import { RefreshingStagesBanner } from '@src/pages/Forms/Candidate/StagesTable/RefreshingStagesBanner'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'

interface Props {
  candidate?: CandidateInterface
  previewStageMode: boolean
  round?: InterviewRoundInterface
  refresh: (force?: boolean, forceRoundLoading?: boolean) => Promise<void>
  refreshStats: () => void
  isLoading?: boolean
  canEditRound: boolean
  fetchRound: (roundId: number) => void
  roundLoading?: boolean
}

const Summary = ({
  candidate,
  round,
  previewStageMode,
  refresh,
  refreshStats,
  isLoading,
  canEditRound,
  fetchRound,
  roundLoading,
}: Props) => {
  const { setActiveAction, activeAction, setActiveStage, activeStage } =
    useCandidateProfileContext()
  const params = useParams<{ id: string }>()
  const { id } = params
  const [selectedFeedbackItem, setSelectedFeedbackItem] =
    useState<InterviewFeedbackWithoutStageInterface>()
  const [selectedStageType, setSelectedStageType] = useState<InterviewType>()
  const canViewEditOffer = candidate?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidateOffers,
  )
  const { data: offerSettings, isLoading: isLoadingOfferSettings } = useGetOfferSettings()
  const {
    data: stages,
    refetch: refetchStages,
    status: stagesLoadingStatus,
    isFetching: stagesFetching,
  } = useFetchInterviewStages(round?.id)

  const { data: interviewRounds, isLoading: loadingInterviewRounds } =
    useGetFullInterviewRounds(id || null)

  const { loading: refreshingStagesLoading } = useRefreshInterviewStages(
    stages,
    stagesLoadingStatus === 'success',
    round?.id,
    () => {
      refresh(undefined, true)
      refetchStages()
    },
  )

  const triggerAction = (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackWithoutStageInterface,
    mode?: ScheduleSidebarModeType,
    actionType?: CandidateSidebarTypes,
  ) => {
    // https://revolut.atlassian.net/wiki/spaces/PP/pages/3444967578/Recruitment+frontend+structure
    // https://revolut.atlassian.net/browse/REVPI-22
    /* TODO: needs refactoring (we have a lot of actions, like opening different modes of scheduling sidebar (editing, rescheduling) as well as other actions, like sending online tests etc, around 11 actions. The way how we are handling it is a bit confusing, we should think of a more transparent approach) */
    // maybe instead of mode and type, have just 1 value, like scheduling:View, scheduling:Rescheduling etc
    if (actionType) {
      setActiveAction({ type: actionType })
      return
    }

    if ('scheduling_status' in data) {
      if (previewStageMode) {
        setActiveAction(undefined)
        setActiveStage(data)
        return
      }

      if (data.interview_type === 'online_test') {
        setActiveAction({
          type: 'onlineTestSummary',
        })
      } else if (mode === 'rescheduling') {
        setActiveAction({
          type: 'schedule',
          mode: 'rescheduling',
        })
      } else if (
        InterviewPendingSchedulingStatuses.includes(data.scheduling_status) &&
        InterviewPendingSchedulingTypes.includes(data.interview_type)
      ) {
        setActiveAction({
          type: 'schedule',
          mode: 'scheduling',
        })
      } else if (InterviewShowScheduleSidebarStatuses.includes(data.scheduling_status)) {
        setActiveAction({
          type: 'schedule',
          mode: 'view',
        })
      } else {
        setActiveAction({
          type: 'shortSummary',
        })
      }

      setActiveStage(data)
      return
    }

    setActiveAction({
      type: 'stage',
    })
  }

  const renderStagesTable = () => {
    if (
      isLoading ||
      roundLoading ||
      stagesLoadingStatus === 'loading' ||
      // react-query doesn't set loading for refetching
      (!stages.length && stagesFetching) ||
      loadingInterviewRounds
    ) {
      return <MultipleDetailsSkeleton />
    }

    if (!round || !candidate) {
      return null
    }

    if (refreshingStagesLoading) {
      return <RefreshingStagesBanner />
    }

    const fullInterviewRound = interviewRounds?.find(
      interviewRound => interviewRound.id === round.id,
    )

    return (
      <Widget p="s-16">
        <StagesTable
          round={round}
          fullInterviewRound={fullInterviewRound}
          onClick={(data, mode, stageType, actionType) => {
            if (stageType === 'offer' || stageType === 'cv_screening') {
              return
            }

            if ('interview_feedbacks' in data) {
              setActiveStage(data)
              setSelectedFeedbackItem(undefined)
            } else {
              setSelectedFeedbackItem(data)
              setActiveStage(undefined)
            }

            setSelectedStageType(stageType)

            triggerAction(data, mode, actionType)
          }}
          selectedItemId={activeStage?.id || selectedFeedbackItem?.id}
          onRefresh={() => {
            refetchStages()
            refresh()
            setActiveAction(undefined)
            setActiveStage(undefined)
          }}
          canViewEditOffer={!!canViewEditOffer}
          disableActions={previewStageMode}
          stages={stages}
          candidateId={candidate.id}
        />
      </Widget>
    )
  }

  const renderComments = () => (
    <Comments
      roundId={round?.id}
      refreshStats={refreshStats}
      onClickSeeAll={() => navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params))}
    />
  )

  const renderJobPostingSwitcher = () => {
    return (
      <HiringStageSwitcher
        interviewRounds={interviewRounds}
        loadingInterviewRounds={loadingInterviewRounds}
        roundId={round?.id}
        candidateId={candidate?.id}
        onChange={fetchRound}
        mainRoundId={candidate?.active_interview_round?.id}
        loading={isLoading}
      />
    )
  }

  let canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  let canRejectFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  if (previewStageMode) {
    canAddFeedback = false
    canRejectFeedback = false
  }

  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)

  return (
    <>
      <>
        <TwoColumnsLayout
          left={
            previewStageMode ? (
              <>
                {round && candidate && (
                  <ChangeRoundWidget
                    candidateId={candidate.id}
                    round={round}
                    onRefresh={refresh}
                  />
                )}
                <ArchiveDetails round={round} />
                <PrepCallInterviewNotifications
                  roundId={round?.id}
                  onView={(interviewId, stageId) => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'view',
                      interviewId,
                      stageId,
                      isPrepCall: true,
                    })
                  }}
                />
                {renderJobPostingSwitcher()}
                {renderStagesTable()}
                {renderComments()}
              </>
            ) : (
              <>
                {candidate?.is_confidential && (
                  <ConfidentialCandidateWidget candidate={candidate} />
                )}
                <SnoozeBanner candidate={candidate} onAfterSubmit={refresh} />
                <AnonymizeNotice date={candidate?.anonymising_expected_date_time} />
                {canViewEditOffer && round && !isLoadingOfferSettings && (
                  <UpcomingOfferNotifications
                    id={round.id}
                    signingEnabled={!!offerSettings?.enable_offer_signing}
                    onRefresh={refresh}
                  />
                )}
                <UpcomingInterviewNotifications id={id} />

                {stages &&
                  !!candidate?.active_interview_round?.latest_interview_stage?.id && (
                    <UpcomingOnlineTestNotifications
                      round={candidate.active_interview_round}
                      onOpenSidebar={() => {
                        setActiveAction({ type: 'sendOnlineTest' })

                        setActiveStage(
                          stages.find(
                            item =>
                              item.id ===
                              candidate.active_interview_round!.latest_interview_stage!
                                .id,
                          ),
                        )
                      }}
                    />
                  )}

                <PrepCallInterviewNotifications
                  roundId={round?.id}
                  onView={(interviewId, stageId) => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'view',
                      interviewId,
                      stageId,
                      isPrepCall: true,
                    })
                  }}
                />
                {candidate && (
                  <CVScreeningNotification
                    candidate={candidate}
                    round={candidate.active_interview_round}
                  />
                )}

                <ArchiveDetails round={round} />
                {candidate && !candidate.active_interview_round && (
                  <Box mt="s-16">
                    <InterviewStartCard candidate={candidate} />
                  </Box>
                )}
                {renderJobPostingSwitcher()}
                {renderStagesTable()}
                {renderComments()}
              </>
            )
          }
          right={
            <CandidateInformation
              candidate={candidate}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
              round={round}
              loading={isLoading}
              previewStageMode={previewStageMode}
              onOpenSidebar={type => {
                setActiveAction({ type })
              }}
            />
          }
          rightMaxWidth={535}
        />
      </>

      <InterviewFeedbackSidebar
        isOpen={activeAction?.type === 'stage'}
        onExit={() => {
          setActiveAction(undefined)
          setSelectedFeedbackItem(undefined)
        }}
        scorecard={selectedFeedbackItem}
        title={selectedFeedbackItem?.interviewer?.display_name}
        round={round}
        stageType={selectedStageType}
      />

      <CandidateShortSummarySidebar
        isOpen={Boolean(activeAction?.type === 'shortSummary' && activeStage)}
        onClose={() => {
          setActiveAction(undefined)
          setSelectedFeedbackItem(undefined)
        }}
        stages={stages}
        stage={activeStage}
        roundId={round?.id}
        onRefresh={() => {
          refresh()
          setActiveAction(undefined)
          setSelectedFeedbackItem(undefined)
        }}
        canAddFeedback={canAddFeedback}
        canRejectFeedback={canRejectFeedback}
        canCanceInterview={!!canRejectFeedback}
        onTriggerStageAction={triggerAction}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        currentStageId={round?.latest_interview_stage?.id}
        candidateId={candidate?.id}
      />

      <OnlineTestSummarySidebar
        isOpen={Boolean(activeAction?.type === 'onlineTestSummary' && activeStage)}
        onClose={() => {
          setActiveAction(undefined)
          setActiveStage(undefined)
        }}
        stage={activeStage}
        roundId={round?.id}
        onRefresh={() => {
          refetchStages()
          refreshStats()
          setActiveAction(undefined)
          setActiveStage(undefined)
        }}
        onTriggerStageAction={triggerAction}
        candidateId={candidate?.id}
      />
      {candidate?.id && activeAction?.type === 'sendOnlineTest' && (
        <SendOnlineTestSidebar
          candidateId={candidate.id}
          candidateEmail={candidate.email}
          roundId={candidate.active_interview_round?.id}
          stageId={activeStage?.id}
          onClose={() => {
            setActiveAction(undefined)
            setActiveStage(undefined)
          }}
          onSuccess={() => {
            refetchStages()
            setActiveAction(undefined)
            setActiveStage(undefined)
          }}
        />
      )}
    </>
  )
}

export default Summary
