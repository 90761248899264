import React, { useMemo } from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { Box, chain, Flex, HStack, Icon, Item, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Id } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'

type OptionType = InterviewRoundInterface | Id<'new'>

type SpecialisationHiringStageProps = {
  item: InterviewRoundInterface
  isMainRound: boolean
}

const SpecialisationHiringStage = ({
  item,
  isMainRound,
}: SpecialisationHiringStageProps) => {
  const mainJobPosting = item?.application
  if ('archived_reason' in item) {
    const specialisationName = item.specialisation.name
    const jobPostingName = mainJobPosting?.job_posting?.name
    const opportunityTitle = jobPostingName || specialisationName
    return (
      <>
        <Text
          variant="h6"
          color={item.archived_reason ? Token.color.greyTone50 : undefined}
        >
          {chain(
            opportunityTitle,
            isMainRound && <Text color={Token.color.green}>Main</Text>,
            !!item.archived_reason && <Text>Archived</Text>,
          )}
        </Text>
        {item.archived_reason && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {chain(
                item.latest_interview_stage_updated_date_time &&
                  formatDate(item.latest_interview_stage_updated_date_time),
                item.archived_reason.name,
              )}
            </Text>
          </Box>
        )}
        {item.latest_interview_stage && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {item.latest_interview_stage.interview_type_display}&nbsp;
              {!!item.stage_count &&
                Number.isFinite(item.latest_interview_stage.interview_number) &&
                `(${item.latest_interview_stage.interview_number}/${item.stage_count})`}
            </Text>
          </Box>
        )}
        {jobPostingName ? (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              Specialisation: {specialisationName}
            </Text>
          </Box>
        ) : (
          <HiringStageJobPostings
            round={item}
            textVariant="small"
            labelVariant="small"
            textColor={Token.color.greyTone50}
          />
        )}
      </>
    )
  }
  return (
    <Flex alignItems="center" color={Token.color.blue}>
      <Icon name="Plus" size={16} color={Token.color.blue} />
      &nbsp;Consider for another opportunity
    </Flex>
  )
}

type Props = {
  interviewRounds?: InterviewRoundInterface[]
  loadingInterviewRounds: boolean
  candidateId?: number
  roundId?: number
  mainRoundId?: number
  onChange: (roundId: number) => void
  loading?: boolean
}

const mapRoundToOption = (item: InterviewRoundInterface) => {
  const mainJobPosting = item?.application
  const jobPostingName = mainJobPosting?.job_posting?.name
  const label = jobPostingName || item?.specialisation?.name
  return {
    key: item.id,
    id: item.id,
    label,
    value: {
      ...item,
      label,
    },
  }
}

const getReferenceUrl = (option: OptionType) => {
  if (option?.id === 'new') {
    return undefined
  }
  const mainJobPosting = option?.application
  const jobPosting = mainJobPosting?.job_posting
  if (jobPosting) {
    return option?.specialisation
      ? pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: option.specialisation.id,
          id: jobPosting?.id,
        })
      : undefined
  }
  return option?.specialisation
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.CANDIDATES, {
        id: option.specialisation.id,
      })
    : undefined
}

export const HiringStageSwitcher = ({
  interviewRounds,
  loadingInterviewRounds,
  candidateId,
  roundId,
  mainRoundId,
  onChange,
  loading,
}: Props) => {
  const isLoading = loading || loadingInterviewRounds

  const options = useMemo<RadioSelectOption<OptionType>[]>(() => {
    const list = (interviewRounds || []).reduce<{
      active: InterviewRoundInterface[]
      archived: InterviewRoundInterface[]
    }>(
      (acc, item) => {
        if (item.archived_reason) {
          acc.archived.push(item)
        } else {
          acc.active.push(item)
        }

        return acc
      },
      { active: [], archived: [] },
    )

    const active = list.active.map(mapRoundToOption)
    const archived = list.archived.map(mapRoundToOption)
    return [
      ...active,
      { key: 'new', id: 'new', label: 'new', value: { id: 'new' } },
      ...archived,
    ]
  }, [interviewRounds])

  const opt = options?.find(item => item.value.id === roundId)
  const optionValue = opt?.value

  return (
    <RadioSelectInput<OptionType>
      label="Opportunity"
      labelPath="label"
      value={optionValue}
      searchable={false}
      onChange={item => {
        if (item && 'specialisation' in item) {
          onChange(item.id)
        } else {
          navigateTo(
            pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
              candidateId,
            }),
          )
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      options={options}
      allowCreateNewOption
      referenceUrl={optionValue ? getReferenceUrl(optionValue) : undefined}
      renderInput={(open, setOpen, ref) => (
        <Item
          aria-label="Opportunity selector"
          ref={ref}
          use="label"
          onClick={() => {
            setOpen(!open)
          }}
        >
          <Item.Content>
            <Item.Title>
              <HStack>
                <Text>{opt?.label || 'Select opportunity'}</Text>
                <Icon use="span" name={open ? 'ChevronUp' : 'ChevronDown'} />
              </HStack>
            </Item.Title>
            <Item.Description>Opportunity</Item.Description>
          </Item.Content>
        </Item>
      )}
    >
      {option => (
        <SpecialisationHiringStage
          item={option.value as InterviewRoundInterface}
          isMainRound={mainRoundId === option.value.id}
        />
      )}
    </RadioSelectInput>
  )
}
